import { actionable } from '@github/catalyst/lib/actionable'

export default actionable(class extends HTMLElement {
  connectedCallback () {
    this.elementIds = JSON.parse(this.dataset.elementIds)
    this.synchronizeControls = this.dataset.synchronizeControls
    this.usePageHash = this.dataset.usePageHash
    this.viewIdPrefix = this.dataset.viewIdPrefix

    const urlHash = window.location.hash.slice(1)

    if (this.usePageHash && this.elementIds.includes(urlHash)) {
      document.querySelectorAll(`[value="${urlHash}"]`).forEach((element) => {
        element.click()
      })
    }
  }

  trigger (event) {
    this.elementIds.forEach((elementId) => {
      const viewId = this.viewIdPrefix ? `${this.viewIdPrefix}-${elementId}` : elementId

      document.getElementById(viewId).classList.toggle('hidden', event.target.value !== elementId)
    })

    if (this.synchronizeControls) {
      document.querySelectorAll(`[value="${event.target.value}"]`).forEach((element) => {
        element.checked = event.target.checked
      })
    }

    if (this.usePageHash && window.history && history.replaceState) {
      history.replaceState(history.state, '', `#${event.target.value}`)
    }
  }
})
