export default class extends HTMLElement {
  connectedCallback () {
    this.baseApiHtml = window.api_body.innerHTML
    this.selectElement = this.querySelector('select')
    this.selectedServer = this.selectElement.value
    this.servers = {
      'api.docuseal.co': {
        apiServer: 'https://api.docuseal.co',
        webServer: 'https://docuseal.co'
      },
      'api.docuseal.eu': {
        apiServer: 'https://api.docuseal.eu',
        webServer: 'https://docuseal.eu'
      },
      custom: {
        apiServer: 'https://yourdomain.com/api',
        webServer: 'https://yourdomain.com'
      }
    }

    this.maybeInitServerFromUrlHash()

    this.selectElement.addEventListener('change', (e) => {
      const selectedValue = e.target.value

      if (selectedValue === 'custom') {
        const newCustomServerUrl = (prompt('Enter your server URL:', this.servers.custom.webServer) || this.servers.custom.webServer).replace(/^(https?:\/\/)?/, 'https://')
        this.servers.custom.apiServer = `${newCustomServerUrl}/api`
        this.servers.custom.webServer = newCustomServerUrl
      }

      this.selectedServer = selectedValue

      this.replaceUrls()
      this.updateUrlHash()
    })
  }

  replaceUrls () {
    if (this.selectedServer === 'api.docuseal.co') {
      window.api_body.innerHTML = this.baseApiHtml
    } else {
      const apiServer = this.servers[this.selectedServer].apiServer
      const webServer = this.servers[this.selectedServer].webServer

      window.api_body.innerHTML = this.baseApiHtml
        .replaceAll('https://api.docuseal.co', apiServer)
        .replaceAll('https://docuseal.co', webServer)
    }

    if (window.get_api_key_button && ['api.docuseal.co', 'api.docuseal.eu'].includes(this.selectedServer)) {
      window.get_api_key_button.href = window.get_api_key_button.href.replace(/docuseal\.(?:co|eu)/g, this.selectedServer.replace('api.', ''))
    }
  }

  maybeInitServerFromUrlHash () {
    const urlHash = window.location.hash.slice(1)

    if (!urlHash?.includes('server=')) {
      return
    }

    const selectedValue = urlHash.match(/server=(.*?)($|:)/)?.[1]
    const customServerUrl = urlHash.split('server=')[1].replace(/^(https?:\/\/)?/, 'https://')

    if (!['api.docuseal.co', 'api.docuseal.eu'].includes(selectedValue) && customServerUrl) {
      this.servers.custom.apiServer = `${customServerUrl}/api`
      this.servers.custom.webServer = customServerUrl
    }

    if (selectedValue && selectedValue !== 'api.docuseal.co') {
      this.selectElement.value = selectedValue === 'api.docuseal.eu' ? 'api.docuseal.eu' : 'custom'
      this.selectedServer = selectedValue === 'api.docuseal.eu' ? 'api.docuseal.eu' : 'custom'

      this.replaceUrls()
    }
  }

  updateUrlHash () {
    if (this.selectedServer === 'custom') {
      window.history.replaceState({}, '', `#server=${this.servers.custom.webServer.replace(/^https?:\/\//, '')}`)
    } else {
      window.history.replaceState({}, '', `#server=${this.selectedServer.replace(/^https?:\/\//, '')}`)
    }
  }
}
