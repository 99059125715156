import { target, targetable } from '@github/catalyst/lib/targetable'

export default targetable(class extends HTMLElement {
  static [target.static] = [
    'canvas',
    'textInput',
    'drawTypeButton',
    'textTypeButton',
    'clearButton',
    'undoButton',
    'saveButton',
    'colorInputs'
  ]

  async connectedCallback () {
    this.scale = 3
    this.isFontLoaded = false

    this.canvas.width = this.canvas.parentNode.parentNode.clientWidth
    this.canvas.height = this.canvas.parentNode.parentNode.clientWidth / this.scale

    const { default: SignaturePad } = await import('signature_pad')

    this.pad = new SignaturePad(this.canvas)

    this.pad.addEventListener('endStroke', () => {
      this.updateDownloadButtonVisibility()
    })

    this.clearButton.addEventListener('click', (e) => {
      e.preventDefault()

      this.cleaSignaturePad()
      this.clearTextInput()
    })

    this.undoButton.addEventListener('click', (e) => {
      e.preventDefault()

      const data = this.pad.toData()

      if (data) {
        data.pop()
        this.pad.fromData(data)
        this.updateDownloadButtonVisibility()
      }
    })

    this.drawTypeButton.addEventListener('click', (e) => {
      e.preventDefault()

      this.textTypeButton.classList.remove('btn-active')
      this.drawTypeButton.classList.add('btn-active')
      this.textInput.classList.add('hidden')
      this.undoButton.classList.remove('hidden')
      this.cleaSignaturePad()
      this.clearTextInput()
    })

    this.textTypeButton.addEventListener('click', (e) => {
      e.preventDefault()

      this.drawTypeButton.classList.remove('btn-active')
      this.textTypeButton.classList.add('btn-active')
      this.textInput.classList.remove('hidden')
      this.undoButton.classList.add('hidden')
      this.cleaSignaturePad()
      this.loadFont()
      this.clearTextInput()
    })

    this.colorInputs.querySelectorAll('input').forEach((input) => {
      input.addEventListener('change', (e) => {
        if (this.textInput.value !== '') {
          this.canvas.getContext('2d').fillStyle = e.target.value
          this.drawText()
        }

        this.pad.penColor = e.target.value
      })
    })

    this.textInput.addEventListener('input', (e) => {
      this.drawText()

      this.updateDownloadButtonVisibility()
    })

    this.saveButton.addEventListener('click', (e) => {
      e.preventDefault()

      this.saveButton.disabled = true

      this.cropCanvas(this.canvas).then((blob) => {
        this.downloadBlob(blob, `signature-${Date.now()}.png`)
      }).then(() => {
        this.saveButton.disabled = false
      }).catch((error) => {
        console.error('Download failed:', error)
        this.saveButton.disabled = false
      })
    })

    this.intersectionObserver = new IntersectionObserver((entries, _observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.canvas.width = this.canvas.parentNode.clientWidth * this.scale
          this.canvas.height = this.canvas.parentNode.clientWidth * this.scale / 3

          this.canvas.getContext('2d').scale(this.scale, this.scale)

          this.intersectionObserver?.disconnect()
        }
      })
    })

    this.intersectionObserver.observe(this.canvas)
  }

  drawText () {
    const canvas = this.canvas
    const context = canvas.getContext('2d')

    const fontFamily = 'Dancing Script'
    const fontSize = '58px'
    const fontStyle = 'italic'
    const fontWeight = ''

    context.font = fontStyle + ' ' + fontWeight + ' ' + fontSize + ' ' + fontFamily
    context.textAlign = 'center'

    context.clearRect(0, 0, canvas.width / this.scale, canvas.height / this.scale)
    context.fillText(this.textInput.value, canvas.width / 2 / this.scale, canvas.height / 2 / this.scale + 11)
  }

  clearTextInput () {
    if (this.textInput) {
      this.textInput.value = ''
      this.updateDownloadButtonVisibility()
    }
  }

  cleaSignaturePad () {
    this.pad.clear()
    this.updateDownloadButtonVisibility()
  }

  updateDownloadButtonVisibility () {
    if (this.pad.isEmpty() && this.textInput.value === '') {
      this.saveButton.disabled = true
    } else {
      this.saveButton.disabled = false
    }
  }

  loadFont () {
    if (!this.isFontLoaded) {
      const font = new FontFace('Dancing Script', 'url(/fonts/DancingScript-Regular.otf) format("opentype")')

      font.load().then((loadedFont) => {
        document.fonts.add(loadedFont)
        this.isFontLoaded = true
      }).catch((error) => {
        console.error('Font loading failed:', error)
      })
    }
  }

  downloadBlob (blob, filename) {
    const url = window.URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.style = 'display: none'
    a.href = url
    a.download = filename

    document.body.appendChild(a)
    a.click()

    window.URL.revokeObjectURL(url)

    return new Promise((resolve) => {
      resolve()
    })
  }

  cropCanvas (canvas) {
    const ctx = canvas.getContext('2d')

    const width = canvas.width
    const height = canvas.height

    let topmost = height
    let bottommost = 0
    let leftmost = width
    let rightmost = 0

    const imageData = ctx.getImageData(0, 0, width, height)
    const pixels = imageData.data

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const pixelIndex = (y * width + x) * 4
        const alpha = pixels[pixelIndex + 3]
        if (alpha !== 0) {
          topmost = Math.min(topmost, y)
          bottommost = Math.max(bottommost, y)
          leftmost = Math.min(leftmost, x)
          rightmost = Math.max(rightmost, x)
        }
      }
    }

    const croppedWidth = rightmost - leftmost + 1
    const croppedHeight = bottommost - topmost + 1

    const croppedCanvas = document.createElement('canvas')
    croppedCanvas.width = croppedWidth
    croppedCanvas.height = croppedHeight
    const croppedCtx = croppedCanvas.getContext('2d')

    croppedCtx.drawImage(canvas, leftmost, topmost, croppedWidth, croppedHeight, 0, 0, croppedWidth, croppedHeight)

    return new Promise((resolve, reject) => {
      croppedCanvas.toBlob((blob) => {
        if (blob) {
          resolve(blob)
        } else {
          reject(new Error('Failed to create a PNG blob.'))
        }
      }, 'image/png')
    })
  }
})
