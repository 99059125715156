import '@hotwired/turbo'
import { encodeMethodIntoRequestBody } from '@hotwired/turbo-rails/app/javascript/turbo/fetch_requests'
import * as TurboInstantClick from './lib/turbo_instant_click'

import './images/preview.png'
import './images/demo.jpg'
import './images/form.jpg'
import './images/builder.jpg'
import './images/integration.png'
import './images/api.png'
import './images/html_to_pdf.png'

import { actionable } from '@github/catalyst/lib/actionable'
import PayButton from './elements/pay_button'
import ClipboardCopy from './elements/copy_to_clipboard'
import SetEuLink from './elements/set_eu_link'
import ApiSnippet from './elements/api_snippet'
import EmbedSnippet from './elements/embed_snippet'
import ToggleVisible from './elements/toggle_visible'
import Plausible from 'plausible-tracker'
import ProcessCheckout from './elements/process_checkout'
import FileDropzone from './elements/file_dropzone'
import CarouselItem from './elements/carousel_item'
import PriceSlider from './elements/price_slider'
import MenuActive from './elements/menu_active'
import HighlightVisible from './elements/highlight_visible'
import ToggleOnChange from './elements/toggle_on_change'
import SignatureForm from './elements/signature_form'
import ApiServerSelect from './elements/api_server_select'
import EmbedAttributeToggle from './elements/embed_attribute_toggle'
import RoughNotation from './elements/rough_notation'
import TestimonialShowMoreButton from './elements/testimonial_show_more_button'
import MergePdfForm from './elements/merge_pdf_form'

require.context('./images/blog', true)
require.context('./images/pages', true)
require.context('./images/resources', true)
require.context('./images/docs', true)
require.context('./images/clients', true)

window.customElements.define('pay-button', PayButton)
window.customElements.define('clipboard-copy', ClipboardCopy)
window.customElements.define('set-eu-link', SetEuLink)
window.customElements.define('api-snippet', ApiSnippet)
window.customElements.define('embed-snippet', EmbedSnippet)
window.customElements.define('toggle-visible', ToggleVisible)
window.customElements.define('process-checkout', ProcessCheckout)
window.customElements.define('file-dropzone', FileDropzone)
window.customElements.define('carousel-item', CarouselItem)
window.customElements.define('price-slider', PriceSlider)
window.customElements.define('menu-active', MenuActive)
window.customElements.define('highlight-visible', HighlightVisible)
window.customElements.define('toggle-on-change', ToggleOnChange)
window.customElements.define('signature-form', SignatureForm)
window.customElements.define('api-server-select', ApiServerSelect)
window.customElements.define('embed-attribute-toggle', EmbedAttributeToggle)
window.customElements.define('testimonial-show-more-button', TestimonialShowMoreButton)
window.customElements.define('rough-notation', RoughNotation)
window.customElements.define('merge-pdf-form', MergePdfForm)

TurboInstantClick.start()

document.addEventListener('turbo:before-cache', () => {
  window.flash?.remove()
})

document.addEventListener('keyup', (e) => {
  if (e.code === 'Escape') {
    document.activeElement?.blur()
  }
})

document.addEventListener('turbo:before-fetch-request', encodeMethodIntoRequestBody)

const modalVideoHTML = `
  <div class="fixed z-10 top-0 bottom-0 right-0 left-0 overflow-y-auto" aria-labelledby="modal-title" role="modal" aria-modal="true">
    <div class="flex justify-center pt-8 px-4 text-center sm:block sm:p-0">
      <div class="fixed top-0 bottom-0 right-0 left-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" data-role="close-modal"></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <div class="inline-block align-bottom text-left overflow-hidden transform transition-all sm:align-middle sm:max-w-5xl sm:w-full">
        <video src="/demo.mp4" controls autoplay width="100%" class="rounded-lg shadow-xl"></video>
        <div class="mt-4 flex justify-center space-x-2">
        <span class="hidden md:inline">
        <a href="#" data-role="close-modal" class="base-button w-44 !text-lg !h-14">
          <span class="text-xl">
            &larr;
          </span>
          Back</a>
          </span>
          <a href="https://docuseal.co/sign_up" class="white-button w-72 !text-lg !h-14" data-role="app-link" data-event="Click Sign Up" data-event-props="{&quot;location&quot;:&quot;Demo video on the landing page&quot;}">
            🚀 Create Free Account</a>
          </a>
        </div>
      </div>
    </div>
  </div>
  `

window.customElements.define('set-video-modal', class extends HTMLElement {
  connectedCallback () {
    document.getElementById(this.dataset.triggerId).addEventListener('click', (e) => {
      e.preventDefault()

      window.history.pushState({}, '', '#demo')

      openVideoModal()
    })

    document.addEventListener('keyup', this.onEscKey)
  }

  disconnectedCallback () {
    document.removeEventListener('keyup', this.onEscKey)
  }

  onEscKey = (e) => {
    if (e.code === 'Escape') {
      closeModal()
    }
  }
})

function openVideoModal () {
  let container = document.querySelector('[data-role="modal"]')

  if (!container) {
    container = document.createElement('div')
    container.setAttribute('data-role', 'modal')

    container.innerHTML = modalVideoHTML
  }

  container.querySelectorAll('[data-role="close-modal"]').forEach((e) => {
    e.addEventListener('click', (el) => {
      el.preventDefault()
      closeModal()
    })
  })

  document.body.appendChild(container)

  initTrackingEventListenersOnPage()
}

function closeModal () {
  window.history.replaceState(window.history.state, '', '#')

  if (document.querySelector('[data-role="modal"] video')) {
    document.querySelector('[data-role="modal"] video').pause()
  }

  if (document.querySelector('[data-role="modal"]')) {
    document.querySelector('[data-role="modal"]').remove()
  }
}

window.onhashchange = function (e) {
  if (e.newURL.includes('#demo')) {
    openVideoModal()
  } else {
    closeModal()
  }
}

window.customElements.define('turbo-modal', actionable(class extends HTMLElement {
  connectedCallback () {
    document.body.classList.add('overflow-hidden')

    document.addEventListener('keyup', this.onEscKey)
    document.addEventListener('turbo:submit-end', this.onSubmit)
    document.addEventListener('turbo:before-cache', this.close)
  }

  disconnectedCallback () {
    document.body.classList.remove('overflow-hidden')

    document.removeEventListener('keyup', this.onEscKey)
    document.removeEventListener('turbo:submit-end', this.handleSubmit)
    document.removeEventListener('turbo:before-cache', this.close)
  }

  onSubmit = (e) => {
    if (e.detail.success) {
      this.close()
    }
  }

  onEscKey = (e) => {
    if (e.code === 'Escape') {
      this.close()
    }
  }

  close = (e) => {
    e?.preventDefault()

    this.remove()
  }
}))

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#demo') && document.location.hash === '#demo') {
    openVideoModal()
  }
})

// Plausible Analytics

const plausible = Plausible({
  apiHost: 'https://plausible.docuseal.tech',
  domain: 'www.docuseal.co'
})

window.plausible = plausible
window.originalReferrer ||= document.referrer

if (process.env.NODE_ENV === 'production') {
  plausible.enableAutoPageviews()
}

function addTrackingEventListener (element) {
  const eventName = element.getAttribute('data-event')
  const eventProps = element.getAttribute('data-event-props')
  const parsedEventProps = eventProps && JSON.parse(eventProps)

  if (eventName) {
    element.setAttribute('data-event-initialized', true)
    element.addEventListener('click', function () {
      plausible.trackEvent(eventName, { props: parsedEventProps })
    })
  }
}

const timezone = window.Intl && new window.Intl.DateTimeFormat()?.resolvedOptions()?.timeZone

function initTrackingEventListenersOnPage () {
  document.querySelectorAll('[data-event]:not([data-event-initialized])').forEach(function (element) {
    addTrackingEventListener(element)
  })
}

document.addEventListener('turbo:load', () => {
  initTrackingEventListenersOnPage()

  if (document.location.host === 'www.docuseal.co') {
    fetch('https://console.docuseal.co/event', {
      method: 'POST',
      body: JSON.stringify({
        type: 'pageview',
        url: document.location.href,
        action: 'pageview',
        referer: window.originalReferrer || document.referrer,
        value: document.location.pathname,
        timezone
      })
    })
  }
})
